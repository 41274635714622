import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./Login.css";
import { backUrl, clientId } from '../../constants';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import LoginImage from './ams_cover_copy.png';
import { GoogleLogin } from '@react-oauth/google';


const Login = ({ children }) => {
    const navigate = useNavigate();

    const [loginError, setLoginError] = useState("");
    const [data, setData] = useState({ email: '', password: '' });
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    //This is for email validation
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    //This is handling the change requests
    const changeHandler = (e) => {
        setData({
            ...data, [e.target.name]: e.target.value
        })
    };

    //This is handling the submit request
    const submitHandler = async (e) => {
        e.preventDefault();

        // Reset previous error messages
        setEmailError('');
        setPasswordError('');
        setLoginError('');

        // Perform validation
        if (data.email === '') {
            setEmailError('Email is required.');
        } else if (!validateEmail(data.email)) {
            setEmailError('Invalid email format.');
        }

        if (data.password === '') {
            setPasswordError('Password is required.');
        }

        // If there are no errors, proceed with login logic
        if (data.email && validateEmail(data.email) && data.password) {
            try {
                const response = await axios.post(backUrl + 'auth/login', data);
                console.log(response);

                //If get succesfull login
                if (response) {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("token2", response.data.token2);
                    navigate('/');
                    window.location.reload();
                };
            } catch (error) {
                console.log(error);
                try {
                    setLoginError(error.response.data.message);
                } catch (error) {
                    setLoginError("Something went wrong");
                }
            };
        };
    };

    const handleGoogleLoginSuccess = async (credentialResponse) => {

        const token = jwt_decode(credentialResponse.credential);

        try {
            const response = await axios.post(backUrl + 'auth/googleLogin', token);

            if (response) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("token2", response.data.token2);
                navigate('/');
                window.location.reload();
            };
        } catch (error) {
            console.log(error);
            try {
                setLoginError(error.response.data.message);
            } catch (error) {
                setLoginError("Something went wrong");
            }
        }
    };

    const handleGoogleLoginError = () => {
        console.log('Login Failed');
    };


    return (
        <>
            <section className="log-in-page">
                <div className="main-page">
                    <h2 className='welcome_text'>Welcome back</h2>

                    <GoogleLogin
                        onSuccess={handleGoogleLoginSuccess}
                        onError={handleGoogleLoginError}
                        shape="pill"
                    />

                    <div className="welcome">
                        <span className="guide">Please enter your details.</span>
                    </div>
                    <p className='inputError pt-3'>{loginError}</p>
                    <form className="form" onSubmit={submitHandler}>
                        <label htmlFor="email" className="label">
                            Email
                            <input name="email" value={data.email} type="text" className="input-field" id="email"
                                placeholder="Enter your email"
                                onChange={changeHandler} />
                        </label>
                        <p className='inputError'>{emailError}</p>

                        <label htmlFor="password" className="label">
                            Password
                            <input name="password" value={data.password} type="password" className="input-field" id="password"
                                placeholder="Enter your password"
                                onChange={changeHandler} />
                        </label>
                        <p className='inputError'>{passwordError}</p>

                        <p><a href="#!">Forgot password?</a></p>

                        <button className="btn" type="submit">LOGIN</button>


                        {/* <button className="btn google_login" type='button' onClick={() => login()}>
                            <i className="bi bi-google me-2"></i>Login With Google
                        </button> */}

                    </form>

                </div>
                <div className="sub-page">
                    <img src={LoginImage}
                        alt="login image"
                        className="img" />
                </div>
            </section>
        </>
    )
}

export default Login