import React from 'react';
import axios from 'axios';
import { backUrl } from '../../constants';

const token = localStorage.getItem('token');
const token2 = localStorage.getItem('token2');

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json', // Set the appropriate content type
};
const header2 = {
    'Authorization': `Bearer ${token2}`,
    'Content-Type': 'application/json', // Set the appropriate content type
};

const Dashboard = () => {
    const refreshData = async () => {
        try {
            await axios.post(backUrl + 'auth/refreshdata', header2, {headers});
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <button onClick={refreshData}>Refresh Data</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard