import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Sidebar from './Components/Sidebar/Sidebar';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from './constants';

function App() {
  const token = localStorage.getItem("token");

  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <Router>
          {token ? <Sidebar /> : <Login />}
        </Router>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
