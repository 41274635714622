import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import Dashboard from '../Pages/Dashboard';
import AllAssets from '../Pages/AllAssets';
import AllUsers from '../Pages/AllUsers';
import Notification from '../Pages/Notification';
import Help from '../Pages/Help';
import axios from 'axios';
import { backUrl } from '../../constants';
import jwt_decode from 'jwt-decode';
import AddAsset from '../Pages/AddAsset';
import ViewUser from '../Pages/ViewUser';
import ViewAsset from '../Pages/ViewAsset';
import Logo from './ams_copy4.png';
import Profile from '../Pages/Profile';
import ChangePassword from '../Pages/ChangePassword';
import EditAsset from '../Pages/EditAsset';
import noPhoto from '../Auth/34AD2.jpg';

const Sidebar = () => {
    const location = useLocation();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userData, setUserData] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(prevState => !prevState);
    };

    const token = localStorage.getItem("token");

    //For Logout
    const logoutHandler = () => {
        localStorage.clear();
        window.location.reload();
    }

    //Get UserDetails
    const getUserDetails = async (id) => {
        try {
            const response = await axios.post(backUrl + "auth/getAllDetails", { userId: id });

            setUserData(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const decodedData = jwt_decode(token);
        setUserData(decodedData);
    }, []);

    return (
        <>
            <div className={sidebarOpen ? "toggle-sidebar" : ""}>
                <header id="header" className="header fixed-top d-flex align-items-center">

                    <div className="d-flex align-items-center justify-content-between">
                        <Link to="" className="logo d-flex align-items-center">
                            <img src={Logo} alt="logo" />
                            {/* <span className="d-none d-lg-block">AMS</span> */}
                        </Link>
                        <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
                    </div>

                    <nav className="header-nav ms-auto">
                        <ul className="d-flex align-items-center">

                            <li className="nav-item d-block d-lg-none">
                                <a className="nav-link nav-icon search-bar-toggle " href="#">
                                    <i className="bi bi-search"></i>
                                </a>
                            </li>

                            <li className="nav-item dropdown">

                                <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                    <i className="bi bi-bell"></i>
                                    <span className="badge bg-primary badge-number">4</span>
                                </a>

                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                                    <li className="dropdown-header">
                                        You have 4 new notifications
                                        <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="notification-item">
                                        <i className="bi bi-exclamation-circle text-warning"></i>
                                        <div>
                                            <h4>Lorem Ipsum</h4>
                                            <p>Quae dolorem earum veritatis oditseno</p>
                                            <p>30 min. ago</p>
                                        </div>
                                    </li>

                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="notification-item">
                                        <i className="bi bi-x-circle text-danger"></i>
                                        <div>
                                            <h4>Atque rerum nesciunt</h4>
                                            <p>Quae dolorem earum veritatis oditseno</p>
                                            <p>1 hr. ago</p>
                                        </div>
                                    </li>

                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="notification-item">
                                        <i className="bi bi-check-circle text-success"></i>
                                        <div>
                                            <h4>Sit rerum fuga</h4>
                                            <p>Quae dolorem earum veritatis oditseno</p>
                                            <p>2 hrs. ago</p>
                                        </div>
                                    </li>

                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="notification-item">
                                        <i className="bi bi-info-circle text-primary"></i>
                                        <div>
                                            <h4>Dicta reprehenderit</h4>
                                            <p>Quae dolorem earum veritatis oditseno</p>
                                            <p>4 hrs. ago</p>
                                        </div>
                                    </li>

                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li className="dropdown-footer">
                                        <a href="#">Show all notifications</a>
                                    </li>

                                </ul>

                            </li>

                            <li className="nav-item dropdown">

                                <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                                    <i className="bi bi-chat-left-text"></i>
                                    <span className="badge bg-success badge-number">3</span>
                                </a>

                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                                    <li className="dropdown-header">
                                        You have 3 new messages
                                        <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="message-item">
                                        <a href="#">
                                            <img src="assets/img/messages-1.jpg" alt="" className="rounded-circle" />
                                            <div>
                                                <h4>Maria Hudson</h4>
                                                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                                <p>4 hrs. ago</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="message-item">
                                        <a href="#">
                                            <img src="assets/img/messages-2.jpg" alt="" className="rounded-circle" />
                                            <div>
                                                <h4>Anna Nelson</h4>
                                                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                                <p>6 hrs. ago</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="message-item">
                                        <a href="#">
                                            <img src="assets/img/messages-3.jpg" alt="" className="rounded-circle" />
                                            <div>
                                                <h4>David Muldon</h4>
                                                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                                                <p>8 hrs. ago</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li className="dropdown-footer">
                                        <a href="#">Show all messages</a>
                                    </li>

                                </ul>

                            </li>

                            <li className="nav-item dropdown pe-3">

                                <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                                    {userData.profilePhoto ? (
                                        <img src={userData.profilePhoto} alt="Profile" className="rounded-circle" />
                                    ) : (
                                        <img src={noPhoto} alt="Profile" className="rounded-circle" />
                                    )}
                                    <span className="d-none d-md-block dropdown-toggle ps-2">{userData.fullname}</span>
                                </a>

                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                    <li className="dropdown-header">
                                        <h6>{userData.fullname}</h6>
                                        <span>{userData.designation}</span>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li>
                                        <Link className="dropdown-item d-flex align-items-center" to="/profile">
                                            <i className="bi bi-person"></i>
                                            <span>My Profile</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <Link className="dropdown-item d-flex align-items-center" to="/changepassword">
                                            <i class="bi bi-key"></i>
                                            <span>Change Password</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li>
                                        <Link className="dropdown-item d-flex align-items-center" to="/help">
                                            <i className="bi bi-question-circle"></i>
                                            <span>Need Help?</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>

                                    <li>
                                        <div className="dropdown-item d-flex align-items-center"
                                            style={{ cursor: "pointer" }}
                                            onClick={logoutHandler}>
                                            <i className="bi bi-box-arrow-right"></i>
                                            <span>Sign Out</span>
                                        </div>
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </nav>
                </header>

                <aside id="sidebar" className="sidebar">

                    {userData.role === 'ADMIN' || userData.role === 'MASTERADMIN' || userData.role === 'SUPERADMIN' ? (
                        <ul className="sidebar-nav" id="sidebar-nav">

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' ? "" : "collapsed"}`} to="">
                                    <i className="bi bi-grid"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>


                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/allusers' || location.pathname.startsWith('/viewuser/') ? "" : "collapsed"}`} to="/allusers">
                                    <i className="bi bi-people"></i>
                                    <span>All Users</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/allassets' || location.pathname.startsWith('/viewasset/') ? "" : "collapsed"}`} to="/allassets">
                                    <i className="bi bi-bag"></i>
                                    <span>All Assets</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/notification' ? "" : "collapsed"}`} to="/notification">
                                    <i className="bi bi-bell"></i>
                                    <span>Notification</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/help' ? "" : "collapsed"}`} to="/help">
                                    <i className="bi bi-question-circle"></i>
                                    <span>Help</span>
                                </Link>
                            </li>
                        </ul>
                    ) : (
                        <ul className="sidebar-nav" id="sidebar-nav">

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' || location.pathname === '/profile' || location.pathname.startsWith('/viewasset/') ? "" : "collapsed"}`} to="">
                                    <i className="bi bi-person-fill"></i>
                                    <span>Profile</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/notification' ? "" : "collapsed"}`} to="/notification">
                                    <i className="bi bi-bell"></i>
                                    <span>Notification</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/help' ? "" : "collapsed"}`} to="/help">
                                    <i className="bi bi-question-circle"></i>
                                    <span>Help</span>
                                </Link>
                            </li>
                        </ul>
                    )}
                </aside>

                {/* This is for routing */}
                <main id="main" className="main" style={{ maxHeight: "100vh", overflowY: "auto" }}>
                    <Routes>
                        {userData.role === 'ADMIN' || userData.role === 'MASTERADMIN' || userData.role === 'SUPERADMIN' ? (
                            <>
                                <Route path="" exact element={<Dashboard />} />
                                <Route path="/allusers" exact element={<AllUsers />} />
                                <Route path="/viewuser/:id" exact element={<ViewUser />} />
                                <Route path="/profile" exact element={<Profile />} />
                                <Route path="/changepassword" exact element={<ChangePassword />} />
                                <Route path="/notification" exact element={<Notification />} />
                                <Route path="/help" exact element={<Help />} />

                                <Route path="/allassets" exact element={<AllAssets />} />
                                <Route path="/addasset" exact element={<AddAsset />} />
                                <Route path="/viewasset/:id" exact element={<ViewAsset />} />
                                <Route path="/editasset/:id" exact element={<EditAsset />} />
                            </>
                        ) : (
                            <>
                                <Route path="/notification" exact element={<Notification />} />
                                <Route path="/help" exact element={<Help />} />
                                <Route path="/viewasset/:id" exact element={<ViewAsset />} />
                                <Route path="" exact element={<Profile />} />
                                <Route path="/profile" exact element={<Profile />} />
                                <Route path="/changepassword" exact element={<ChangePassword />} />
                            </>
                        )}
                    </Routes>
                </main>

                <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
            </div>
        </>
    )
}

export default Sidebar