import React, { useState } from 'react';
import './AddAsset.css';
import axios from 'axios';
import { backUrl } from '../../constants';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const AddAsset = () => {
    const navigate = useNavigate();

    const [assetNameError, setAssetNameError] = useState("");
    const [serialNumberError, setSerialNumberError] = useState("");
    const [assignableError, setAssignableError] = useState("");
    const [brandNameError, setBrandNameError] = useState("");
    const [modelNumberError, setModelNumberError] = useState("");
    const [myFile, setMyFile] = useState("");

    const [fields, setFields] = useState([{ property: '', value: '' }]);
    const [assetData, setAssetData] = useState({
        basicDetails: {
            assetName: '',
            serialNumber: '',
            assetType: '', // Set a default value
            purchasedDate: '',
        },
        specifications: {
            brandName: '',
            modelNumber: '',
            fields: [{ property: '', value: '' }],
        },
        documentDetails: {
            warrantyExpire: '',
            photoAsset: null,
            invoiceAsset: null,
            otherDocAsset: null,
        },
    });

    const addField = () => {
        const updatedFields = [...assetData.specifications.fields, { property: '', value: '' }];
        setAssetData({
            ...assetData,
            specifications: {
                ...assetData.specifications,
                fields: updatedFields,
            },
        });
    };

    const removeField = (index) => {
        const updatedFields = [...assetData.specifications.fields];

        if (updatedFields.length === 1) {
            return; // Prevent removing when there are no fields
        }

        updatedFields.splice(index, 1);
        setAssetData({
            ...assetData,
            specifications: {
                ...assetData.specifications,
                fields: updatedFields,
            },
        });
    };

    const handleFieldChange = (section, fieldName, value) => {
        setAssetNameError("");
        setSerialNumberError("");
        setAssignableError("");
        setBrandNameError("");
        setModelNumberError("");

        setAssetData({
            ...assetData,
            [section]: {
                ...assetData[section],
                [fieldName]: value,
            },
        });
    };

    const handleSpecificationFieldChange = (index, fieldName, value) => {
        const updatedFields = [...assetData.specifications.fields];
        updatedFields[index][fieldName] = value;
        setAssetData({
            ...assetData,
            specifications: {
                ...assetData.specifications,
                fields: updatedFields,
            },
        });
    };

    const handleFileChange = (e) => {
        const files = e.target.files;

        if (e.target.name === 'photoAsset') {
            setAssetData((prevData) => ({
                ...prevData,
                documentDetails: {
                    ...prevData.documentDetails,
                    photoAsset: files[0], // Only take the first file for simplicity
                },
            }));
        } else if (e.target.name === 'invoiceAsset') {
            setAssetData((prevData) => ({
                ...prevData,
                documentDetails: {
                    ...prevData.documentDetails,
                    invoiceAsset: files[0],
                },
            }));
        } else if (e.target.name === 'otherDocAsset') {
            setAssetData((prevData) => ({
                ...prevData,
                documentDetails: {
                    ...prevData.documentDetails,
                    otherDocAsset: files[0],
                },
            }));
        }
    };

    const formValidation = () => {
        let isValid = true;

        if (assetData.basicDetails.assetName.trim().length === 0) {
            isValid = false;
            setAssetNameError("Please enter asset name");
        }
        if (assetData.basicDetails.serialNumber.trim().length === 0) {
            isValid = false;
            setSerialNumberError("Please enter serial number");
        }
        if (assetData.basicDetails.assetType.trim().length === 0) {
            isValid = false;
            setAssignableError("Please select assign type");
        }
        if (assetData.specifications.brandName.trim().length === 0) {
            isValid = false;
            setBrandNameError("Please enter brand name");
        }
        if (assetData.specifications.modelNumber.trim().length === 0) {
            isValid = false;
            setModelNumberError("Please enter model number");
        }
        return isValid;
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const validForm = formValidation();

        const formData = new FormData();

        formData.append("photoAsset", assetData.documentDetails.photoAsset);
        formData.append("invoiceAsset", assetData.documentDetails.invoiceAsset);
        formData.append("otherDocAsset", assetData.documentDetails.otherDocAsset);
        // ... Append other form fields to formData
        formData.append("basicDetails", JSON.stringify(assetData.basicDetails));
        formData.append("specifications", JSON.stringify(assetData.specifications));
        formData.append("documentDetails", JSON.stringify(assetData.documentDetails));


        console.log(formData.get('basicDetails'));

        if (validForm) {
            const token = localStorage.getItem('token');

            try {
                const response = await axios.post(backUrl + "asset/createAsset", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data', // Set the correct Content-Type header
                    },
                });

                console.log(response.data);

                MySwal.fire({
                    title: <p>Asset Added Successfully</p>,
                    type: "success",
                });

                navigate("/allassets");

            } catch (error) {
                console.log(error);
                try {
                    if (error.response.status === 400) {
                        MySwal.fire({
                            title: <p>Asset already exists</p>,
                            type: "error",
                        });
                    }
                } catch (error) {
                    MySwal.fire({
                        title: <p>Something went wrong</p>,
                        type: "error",
                    });
                }
            };
        } else {
            MySwal.fire({
                title: <p>Please fill mandatory fields</p>,
                type: "error",
            });
        }
    };

    return (
        <>
            <form onSubmit={submitHandler}>
                <div className='container-fluid overall_div'>
                    <h3 className='text-center h3_addAsset'>ADD ASSET</h3>

                    {/* Basic details starts from here */}
                    <div className='row form_row'>
                        <h5 className='heading2'>Basic Details</h5>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="text" name="assetName" placeholder='' className="form-control"
                                    onChange={(e) => handleFieldChange('basicDetails', 'assetName', e.target.value)}
                                />
                                <label htmlFor="assetName">Asset Name *</label>
                                <p className='inputError'>{assetNameError}</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="text" name="serialNumber" placeholder='' className="form-control"
                                    onChange={(e) => handleFieldChange('basicDetails', 'serialNumber', e.target.value)}
                                />
                                <label htmlFor="serialNumber">Serial Number *</label>
                                <p className='inputError'>{serialNumberError}</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating">
                                <select className="form-select" name="assetType" aria-label="Floating label select example"
                                    onChange={(e) => handleFieldChange('basicDetails', 'assetType', e.target.value)}>
                                    <option value="" selected>Select Asset Type</option>
                                    <option value="assignable">Assignable</option>
                                    <option value="non_assignable">Non-assignable</option>
                                </select>
                                <label htmlFor="assetType">Works with selects</label>
                                <p className='inputError'>{assignableError}</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="date" name="purchasedDate" className="form-control"
                                    onChange={(e) => handleFieldChange('basicDetails', 'purchasedDate', e.target.value)}
                                />
                                <label htmlFor="purchasedDate">Purchased Date</label>
                            </div>
                        </div>
                    </div>

                    <hr className='mt-5' />


                    {/* Specification starts from here */}
                    <div className='row form_row'>
                        <h5 className='heading2'>Specifications</h5>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="text" name="brandName" placeholder='' className="form-control"
                                    onChange={(e) => handleFieldChange('specifications', 'brandName', e.target.value)}
                                />
                                <label htmlFor="brandName">Brand Name</label>
                                <p className='inputError'>{brandNameError}</p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="text" name="modelNumber" placeholder='' className="form-control"
                                    onChange={(e) => handleFieldChange('specifications', 'modelNumber', e.target.value)}
                                />
                                <label htmlFor="modelNumber">Model Number</label>
                                <p className='inputError'>{modelNumberError}</p>
                            </div>
                        </div>

                        {/* Fields starts from here */}
                        {assetData.specifications.fields.map((field, index) => (
                            <div className='row' key={index}>
                                <div className='col-lg-4 col-sm-12 input_div'>
                                    <div className="form-floating mb-3">
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Property'
                                            value={field.property}
                                            onChange={(e) => handleSpecificationFieldChange(index, 'property', e.target.value)}
                                        />
                                        <label htmlFor={`property_${index}`} className='form-label'>Property</label>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-12 input_div' key={index}>
                                    <div className="form-floating mb-3">
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Value'
                                            value={field.value}
                                            onChange={(e) => handleSpecificationFieldChange(index, 'value', e.target.value)}
                                        />
                                        <label htmlFor={`value_${index}`} className='form-label'>Value</label>
                                    </div>
                                </div>
                                {index >= 0 && (
                                    <>
                                        <div className="d-grid gap-2 col-lg-2 col-sm-6">
                                            <button
                                                className='btn btn-danger remove_field_btn'
                                                onClick={() => removeField(index)}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                        <div className="d-grid gap-2 col-lg-2 col-sm-6">
                                            <button className="btn btn-primary add_more_btn" type="button" onClick={addField}>Add More</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>

                    <hr className='mt-5' />


                    {/* Decument details starts from here */}
                    <div className='row form_row'>
                        <h5 className='heading2'>Document Details</h5>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="date" name="warrantyExpire" className="form-control"
                                    onChange={(e) => handleFieldChange('documentDetails', 'warrantyExpire', e.target.value)}
                                />
                                <label htmlFor="warrantyExpire" className="form-label">Warranty Expire</label>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="file" name="photoAsset" className="form-control"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="photoAsset" className="form-label">Photo of Asset</label>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="file" name="invoiceAsset" className="form-control"
                                    accept="image/*,application/pdf"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="invoiceAsset" className="form-label">Invoice of Asset</label>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 input_div'>
                            <div className="form-floating mb-3">
                                <input type="file" name="otherDocAsset" className="form-control"
                                    accept="image/*,application/pdf"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="otherDocAsset" className="form-label">Other Documents of Asset</label>
                            </div>
                        </div>
                    </div>

                    <div className='row form_row justify-content-center'>
                        <div className="d-grid gap-2 col-lg-2 col-sm-6">
                            <button className="btn btn-primary submit_btn" type="submit">Submit</button>
                        </div>
                        <div className="d-grid gap-2 col-lg-2 col-sm-6">
                            <button className="btn cancel_btn" type="button" onClick={() => navigate("/allassets")}>Cancel</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddAsset